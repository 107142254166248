<template>
  <v-card class="text-center full-height">
    <v-img 
      class="mx-auto"
      :src="productImage"
      height="80px"
    ></v-img>
    <v-card-text class="min-height-card-text">
      <h4>{{ product.nombre_producto }}</h4>
      <h5>{{ productOption.nombre_opcion }}</h5>
      <h3>${{ productPrice }}</h3>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="warning"
        block
        tile
        @click="emitAdd"
      >
        <span class="black--text">Agregar</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    images: {
      type: Array,
      default: () => []
    },
    prices: {
      type: Array,
      default: () => []
    },
    taxes: {
      type: Array,
      default: () => []
    },
    productOption: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    productImage() {
      const [firstImage] = this.product.imagenes
      const image = this.images.find(img => img.id === firstImage)
      return image ? image.imagen : null
    },
    productPrice() {
      const [firstPrice] = this.product.precios
      const price = this.prices.find(pr => pr.id === firstPrice)
      return price ? price.precio_distribuidor : null
    },
    productTax() {
      // Aqui se deberia considerar todos los
      // impuestos, se debe mandar el array de
      // valores de impuesto?
      const [firstTax] = this.product.impuesto
      const tax = this.taxes.find(tx => tx.id === firstTax)
      return tax ? tax.valor_impuesto : null
    }
  },
  methods: {
    emitAdd() {
      const itemToAdd = {
        productId: this.product.id,
        productName: this.product.nombre_producto,
        price: this.productPrice,
        image: this.productImage,
        tax: this.productTax,
        optionId: this.productOption.id,
        optionName: this.productOption.nombre_opcion
      }
      this.$emit('actionAdd', itemToAdd)
    }
  }
}
</script>

<style>
.full-height {
  height: 100%;
}
.min-height-card-text {
  height: 100px;
}
</style>