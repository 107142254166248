<template>
  <v-row class="mt-5">
    <v-col
      cols="12"
      md="8"
      lg="8"
      xl="9"
    >
      <v-row>
        <v-col>
          <kn-searcher v-model="search" />
        </v-col>
      </v-row>
      <kn-product-container>
        <v-col
          v-for="item in inventoryFiltered" 
          :key="item.id"
          cols="12"
          md="4"
          lg="3"
          xl="2"
        >
          <kn-product-item 
            :product="item.producto"
            :productOption="item.opcion_producto"
            :images="images"
            :prices="prices"
            :taxes="taxes"
            @actionAdd="addProduct"
          />
        </v-col>
      </kn-product-container>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
      xl="3"
    >
      <kn-ticket-container
        :ticketNumber="orderNumber"
        :customerName="customerFullName"
        :sellerName="nameUserLoggedIn"
        :itemsCount="ticketTotalItems"
        :subtotalTicket="ticketSubTotalPrice"
        :amountTaxes="ticketSubTotalTaxes"
        :bigTotal="ticketTotal"
        @actionContinue="openPaymentDialog = true"
        @actionSave="saveToLocalStorage"
        @actionCancel="cancelSessionOrder"
      >
        <template #items>
          <kn-ticket-item
            v-for="item in ticketItems"
            :key="`${item.productName}-${item.optionName}`"
            :item="item"
            :quantity=item.quantity
            @actionDecrement="decrementQuantity"
            @actionIncrement="incrementQuantity"
          />
        </template>
      </kn-ticket-container>
    </v-col>
    <v-dialog
      v-model="openSessionDialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card tile>
        <v-toolbar
          color="primary"
          dark
        >
          {{ sessionSelectionTitle }}
        </v-toolbar>
        <v-card-text class="text-center py-10 px-12">
          <v-btn
            v-if="stepSessionSelection"
            class="my-1"
            color="primary"
            block
            outlined
            tile
            @click="showCustomerSelection"
          >
            Nueva sesión
          </v-btn>
          <v-btn
            v-if="stepSessionSelection"
            class="my-1"
            color="info"
            block
            outlined
            tile
            @click="setCustomerFromLocalStorage"
          >
            Sesión guardada
          </v-btn>
          <v-autocomplete
            v-if="stepCustomerSelection"
            v-model="customerSelected"
            placeholder="Alumno"
            dense
            outlined
            return-object
            :items="validStudents"
            item-text="nombre_completo"
            item-value="id"
            @change="setCustomer"
          />
          <v-btn
            v-if="stepCustomerSelection"
            text
            color="info"
            block
            tile
            @click="setGeneralPublicAsCustomer"
          >
            Venta a público en general
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            tile
            @click="goTo('Ordenes')"
          >
            Salir de venta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openPaymentDialog"
      persistent
      max-width="900"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title>Cobro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="openPaymentDialog = false"
            >
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title class="px-14">
          <h4>Ticket #{{ orderNumber }}</h4>
          <v-spacer></v-spacer>
          <h6 class="graydefault--text">{{ today() }}</h6>
        </v-card-title>
        <v-card-subtitle class="px-14">
          Cliente: {{ customerFullName }} <br>
          Vendedor: {{ nameUserLoggedIn }}
        </v-card-subtitle>
        <v-card-text class="text-center  px-12">
          <v-row>
            <v-col>
              <v-simple-table
                dense
                fixed-header
                max-height="300px"
              >
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Producto</th>
                      <th class="text-left">Opción</th>
                      <th class="text-right">Cantidad</th>
                      <th class="text-right">Precio Uni.</th>
                      <th class="text-right">Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in ticketItems"
                      :key="`${item.productName}${item.optionName}-cobro`"
                    >
                      <td class="text-left">{{ item.productName }}</td>
                      <td class="text-left">{{ item.optionName }}</td>
                      <td class="text-right">{{ item.quantity }}</td>
                      <td class="text-right">{{ item.price }}</td>
                      <td class="text-right">{{ (item.quantity * item.price).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                class="mt-4 font-weight-bold no-pointer-events"
                dense
              >
                <template #default>
                  <tbody>
                    <tr>
                      <td class="text-left graydefault--text no-border">Cantidad de productos</td>
                      <td class="text-right graydefault--text no-border">{{ ticketTotalItems }}</td>
                    </tr>
                    <tr>
                      <td class="text-left graydefault--text no-border">Subtotal</td>
                      <td class="text-right graydefault--text no-border">${{ ticketSubTotalPrice.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="text-left graydefault--text no-border">Impuestos</td>
                      <td class="text-right graydefault--text no-border">${{ ticketSubTotalTaxes.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table
                class="font-weight-bold no-pointer-events"
                dense
              >
                <template #default>
                  <tbody>
                    <tr>
                      <td class="text-left black--text no-border text-h6">Total</td>
                      <td class="text-right black--text no-border text-h6">${{ ticketTotal.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <kn-select
                class="mt-5 text-left"
                v-model="paymentMethodId"
                label="Método de pago"
                :rules=[]
                :items="paymentMethods"
                item-text="dato"
                item-value="id"
              />
              <kn-select
                class="mt-5 text-left"
                v-model="orderStatusId"
                label="Estatus de orden"
                :rules=[]
                :items="validOrderStatus"
                item-text="dato"
                item-value="id"
              />
              <kn-select
                class="text-left"
                v-model="accountId"
                label="Cuenta destino"
                :rules=[]
                :items="bankAccounts"
                item-text="nombre_cuenta"
                item-value="id"
              />
              <kn-text-field
                v-if="isCash"
                v-model.number="minuend"
                class="text-left"
                label="Recibido"
                :rules="[]"
                @input="calculateRest"
              />
              <kn-text-field
                v-if="isCash"
                v-model="rest"
                class="text-left"
                label="Cambio"
                :rules="[]" 
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            tile
            @click="createOrder"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </v-row>
</template>

<script>
import KnProductContainer from '@/components/pos/sales/KnProductContainer.vue'
import KnProductItem from '@/components/pos/sales/KnProductItem.vue'
import KnSearcher from '@/components/pos/sales/KnSearcher.vue'
import KnTicketContainer from '@/components/pos/sales/KnTicketContainer.vue'
import KnTicketItem from '@/components/pos/sales/KnTicketItem.vue'
import { apiMixin } from '@/mixins/apiMixin.js'
import { arrayUtilsMixin } from '@/mixins/arrayUtilsMixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
export default {
  components: {
    KnProductContainer,
    KnProductItem,
    KnSearcher,
    KnTicketContainer,
    KnTicketItem,
    KnSelect,
    KnTextField,
    KnLocalAlert
  },
  mixins: [apiMixin, arrayUtilsMixin],
  props: {
    tabValue: {
      type: Number,
      default: 66
    }
  },
  data() {
    return {
      inventory: [],
      images: [],
      prices: [],
      taxes: [],
      students: [],
      paymentMethods: [],
      bankAccounts: [],
      orderStatus: [],
      shippingTypes: [],
      coins: [],
      customerSelected: null,
      orderStatusId: null,
      ticketOrderNumber: null,
      openSessionDialog: false,
      openPaymentDialog: false,
      sessionExist: true,
      stepSessionSelection: false,
      stepCustomerSelection: false,
      paymentMethodId: null,
      accountId: null,
      mxCoin: null,
      search: null,
      minuend: 0,
      rest: 0,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['nameUserLoggedIn']),
    ...mapGetters('pos', [
      'ticketItems',
      'ticketSubTotalPrice',
      'ticketTotalItems',
      'ticketSubTotalTaxes',
      'ticketTotal',
      'isSessionOrderEmpty',
      'customerId',
      'orderNumber'
    ]),
    sessionSelectionTitle() {
      return this.stepSessionSelection ? 'Selecciona una sesión' : 'Selecciona un cliente'
    },
    validStudents() {
      return this.students.filter(student => student.estatus_sistema === true 
                                              && student.datos_personales.primer_nombre !== 'Publico')
    },
    validOrderStatus() {
      return this.orderStatus.filter(status => status.estatus_sistema === true)
    },
    customerFullName() {
      return this.customerSelected ? this.fullName(this.customerSelected.datos_personales) : ''
    },
    isCash() {
      return this.paymentMethods.find(pm => pm.id === this.paymentMethodId && pm.dato === "Efectivo")
    },
    searchIsEmpty() {
      return this.search === '' 
              || this.search === null
    },
    inventoryFiltered() {
      return this.searchIsEmpty
              ? this.inventory
              : this.filterIt(this.inventory, this.search)
    },
    ticketVolumetricWeight() {
      const ticketItems = [...this.ticketItems]
      let volumetricWeight = 0
      ticketItems.forEach(item => {
        // console.log('item: ', item);
        const inventorySelected = this.inventory.find(inventory => inventory.producto.id === item.productId)
        // console.log('Producto seleccionado peso vol.:', inventorySelected, inventorySelected.producto.peso_volumetrico);
        volumetricWeight = volumetricWeight + parseFloat(inventorySelected.producto.peso_volumetrico)
      })
      // console.log('peso volumetrico: ', volumetricWeight);
      return volumetricWeight
    },
    comments() {
      const names = this.ticketItems.map(item => `${item.quantity} ${item.productName}`)
      const productNames = names.join(', ')

      const comments = `Creado desde punto de venta. Orden ${this.orderNumber} - Productos: ${productNames}`
      return comments
    }
  },
  watch: {
    isSessionOrderEmpty (val) {
      this.sessionExist = !val
      if (this.sessionExist) {
        this.showSessionSelection()
      } else {
        this.showCustomerSelection()
      }
    },
    isCash(obj) {
      if (obj) {
        const status = this.orderStatus.find(item => item.dato === 'Pagada')
        this.orderStatusId = status.id
      }
    }
  },
  async created() {
    this.setIsPOS(true)
    this.inventory = await this.fetchResultsByEI('inventarios', 'inventario', this.institutionId)
    this.images = await this.fetchResultsByEI('productos', 'imagen-producto', this.institutionId)
    this.prices = await this.fetchResultsAll('productos', 'precio-distribuidor-all')
    this.taxes = await this.fetchResultsByEI('productos', 'impuesto', this.institutionId)
    const allStudents = await this.fetchResultsByEI('personas', 'alumno', this.institutionId)
    const allPaymentMethods = await this.fetchResultsByEI('administracion', 'forma-de-pago', this.institutionId)
    const allOrderStatus = await this.fetchResultsAll('ordenes', 'mv-estatus-orden-all')
    const allBankAccounts = await this.fetchResultsByEI('administracion', 'cuanta', this.institutionId)
    this.paymentMethods = this.activeItems(allPaymentMethods)
    this.bankAccounts = this.activeItems(allBankAccounts)
    this.orderStatus = allOrderStatus.filter(oStatus => oStatus.dato.toLowerCase().includes('pagada'))
    this.shippingTypes = await this.fetchResultsAll('ordenes', 'tipo-envio-all')
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)

    allStudents.forEach(student => {
      const {
        primer_nombre: pn,
        segundo_nombre: sn,
        apellido_paterno: ap,
        apellido_materno: am
      } = student.datos_personales
      const fullName = `${pn} ${sn} ${ap} ${am}`
      const item = {
        nombre_completo: fullName,
        ...student
      }
      this.students.push(item)
    })

    this.loadTicketFromStorage()
    this.sessionExist = !this.isSessionOrderEmpty
    if (this.sessionExist) {
      this.showSessionSelection()
    } else {
      this.showCustomerSelection()
    }
    this.openSessionDialog = true
  },
  beforeRouteLeave(to, from, next) {
    this.setIsPOS(false)
    next()
  },
  methods: {
    ...mapMutations(['setIsPOS']),
    ...mapMutations('pos', [
      'pushProductToTicket',
      'incrementItemQuantity',
      'setSessionOrderCustomer',
      'setSessionOrderNumber'
    ]),
    ...mapActions('pos', [
      'loadTicketFromStorage',
      'cancelSession',
      'addProductToTicket',
      'decrementItemQuantity'
    ]),
    addProduct(item) {
      const inventorySelected = this.inventory.find(inv => inv.producto.id === item.productId 
                                                            && inv.opcion_producto.id === item.optionId )
      const itemToAdd = {
        ...item,
        inventory: { ...inventorySelected }
      }
      // console.log('itemToAdd:', itemToAdd);
      this.addProductToTicket(itemToAdd)
    },
    incrementQuantity(item) {
      this.incrementItemQuantity(item)
    },
    decrementQuantity(item) {
      this.decrementItemQuantity(item)
    },
    showSessionSelection() {
      this.stepSessionSelection = true
      this.stepCustomerSelection = false
    },
    showCustomerSelection() {
      this.stepSessionSelection = false
      this.stepCustomerSelection = true

      this.cancelSession()
    },
    setGeneralPublicAsCustomer() {
      const genericStudent = this.students.find(student => student.datos_personales.primer_nombre === 'Publico')
      this.customerSelected = genericStudent
      this.setCustomer()
    },
    setCustomer() {
      this.ticketOrderNumber = this.getOrderNumber()
      this.setSessionOrderCustomer(this.customerSelected.id)
      this.setSessionOrderNumber(this.ticketOrderNumber)
      this.openSessionDialog = false
    },
    setCustomerFromLocalStorage() {
      const customer = this.students.find(student => student.id === this.customerId)
      this.customerSelected = customer
      this.openSessionDialog = false
    },
    saveToLocalStorage() {
      localStorage.setItem('customerId', JSON.stringify(this.customerSelected.id))
      localStorage.setItem('ticket', JSON.stringify(this.ticketItems))
      localStorage.setItem('orderNumber', JSON.stringify(this.orderNumber))
    },
    clearLocalStorage() {
      localStorage.removeItem('customerId')
      localStorage.removeItem('ticket')
      localStorage.removeItem('orderNumber')
    },
    cancelSessionOrder() {
      this.cancelSession()
      this.customerSelected = null
      this.openSessionDialog = true
    },
    goTo(routeName) {
      this.$router.push({name: routeName})
    },
    fullName({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am
    }) {
      return `${pn} ${sn} ${ap} ${am}`
    },
    getOrderNumber() {
      const now = new Date()
      const timeInMili = now.getTime()
      const timeString = timeInMili.toString()
      return timeString.substring(8, timeString.length)
    },
    async createOrder() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        this.openPaymentDialog = false

        const responsibleTutor = this.customerSelected.tutores.find(tutor => tutor.responsable_economico === true)
        const tutorTaxId = responsibleTutor.datos_fiscales.slice(0, 1).shift()

        const shippingType = this.shippingTypes.find(sType => sType.dato.toLowerCase().includes('mostrador') )

        const customerAddressId = this.customerSelected.datos_personales.direccion

        this.mxCoin = this.coins.find(coin => coin.codigo_iso === 'MXN')

        const orderStatusSelected = this.orderStatus.find(oStatus => oStatus.id === this.orderStatusId)

        const orderObj = {
          numero_orden: this.orderNumber,
          id_estatus_orden: this.orderStatusId,
          id_alumno: this.customerId,
          id_datos_facturacion: tutorTaxId,
          id_institucion_educativa: this.institutionId,
          id_tipo_envio: shippingType.id,
          id_direccion: customerAddressId,
          peso_volumetrico: this.ticketVolumetricWeight,
          sub_total: this.ticketSubTotalPrice,
          total_impuestos: this.ticketSubTotalTaxes,
          costo_envio: 0.00,
          total_descuento: 0.00,
          total_orden: this.ticketTotal,
          id_moneda: this.mxCoin.id,
          descuento_aplicado: false,
          id_autor: this.userData.id
        }
        // console.log('Crear orden');
        // this.goTo('Ordenes')
        const response = await this.postObj('/ordenes/crear-orden', orderObj)
        if (response.id_objeto) {
          this.alertText = 'Orden creada con éxito'

          const orderId = response.id_objeto
          for (const item of this.ticketItems) {
            await this.createOrderDetail(orderId, item)
            await this.updateInventory(item)
            await this.createInventoryMovement(item)
          }
          const orderStatusData = orderStatusSelected.dato.toLowerCase()
          if (orderStatusData.includes('pagada') 
              || orderStatusData.includes('completa') 
              || orderStatusData.includes('completada')
          ) {
            await this.createIncome()
          } else {
            await this.createDebt()
          }
        } else {
          this.errors.push(response.error)
        }

        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = 'success'
          this.alertColor = 'success'
          this.alertText = 'Venta registrada con éxito!'
        }
        this.loading = false
      } catch (error) {
        console.error('Error al intentar crear orden', error);
        this.errors.push('Error al intentar crear orden')
      }
    },
    async createOrderDetail(orderId, ticketItem) {
      try {
        const orderDetail = {
          id_orden: orderId,
          id_producto: ticketItem.productId,
          unidades: ticketItem.quantity,
          id_unidad_medida: ticketItem.inventory.unidad_medida.id,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }
        const response = await this.postObj('/ordenes/crear-detalle-orden', orderDetail)
        if (response) {
          this.alertText = 'Detalle de orden creada con éxito'
        } else {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear detalle de orden', error);
        this.errors.push('Error al intentar crear detalle de orden')
      }
    },
    async updateInventory(ticketItem) {
      try {
        const inventory = {...ticketItem.inventory}

        const response = await this.postObj('/inventarios/update-inventario', {
          id: inventory.id,
          id_producto: ticketItem.productId,
          id_opcion_producto: inventory.opcion_producto.id,
          id_almacen: inventory.almacen.id,
          cantidad_disponible: inventory.cantidad_disponible - ticketItem.quantity,
          id_unidad_de_medida: inventory.unidad_medida.id,
          comentarios: inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        })
        if (response) {
          this.alertText = 'Inventario actualizado con exito'
        } else {
          this.errors.push('No se pudo actualizar inventario')
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario');
        this.errors.push('Error al intentar actualizar inventario')
      }
    },
    async createInventoryMovement(ticketItem) {
      try {
        this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        const movementType = this.movementTypes.find(mType => mType.dato.toLowerCase().includes('salida'))
        const movementReason = this.movementReasons.find(mReason => mReason.dato.toLowerCase().includes('venta'))

        const inventory = {...ticketItem.inventory}

        const initialAmount = parseFloat(inventory.cantidad_disponible)
        const amountOfMovement = ticketItem.quantity
        const finalAmount = initialAmount - amountOfMovement
        const unitPrice = parseFloat(ticketItem.price)
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          id_producto: ticketItem.productId,
          id_tipo_movimiento: movementType.id, // Tipo de movimiento: Salida
          id_motivo_movimiento: movementReason.id, // Motivo de movimiento: Venta
          id_almacen: inventory.almacen.id,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.mxCoin.id,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }

        const response = await this.postObj('/inventarios/crear-movimiento-inventario', movementObj)

        if (response) {
          this.alertText = 'Movimiento de inventario creado con exito'
        } else {
          this.errors.push('No se pudo crear movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    },
    async createIncome() {
      try {
        const incomeCategories = await this.fetchResultsByEI('administracion', 'categoria-ingreso', this.institutionId)
        const incomeStatus = await this.fetchResultsByEI('administracion', 'estatus-ingreso', this.institutionId)
        const penalties = await this.fetchResultsByEI('administracion', 'penalizacion', this.institutionId)

        const saleCategory = incomeCategories.find(iCategory => iCategory.dato.toLowerCase().includes('venta'))
        const paidOffStatus = incomeStatus
                                .find(iStatus => iStatus.dato.toLowerCase().includes('pagado') 
                                                || iStatus.dato.toLowerCase().includes('completado')
                                                || iStatus.dato.toLowerCase().includes('completo'))
        const penalty = penalties.find(p => p.nombre.toLowerCase().includes('venta'))

        const incomeObj = {
          id_alumno: this.customerSelected.id,
          id_adeudo: null,
          id_categoria: saleCategory.id,
          sub_total: this.ticketSubTotalPrice,
          total_impuestos: this.ticketSubTotalTaxes,
          costo_envio: 0.00,
          total_descuento: 0.00,
          total_penalizaciones: 0.00,
          total_ingreso: this.ticketTotal,
          descuento_aplicado: false,
          penaliacion_aplicada: false,
          id_penalizacion: penalty.id,
          id_forma_de_pago: this.paymentMethodId,
          id_moneda: this.mxCoin.id,
          id_cuenta_destino: this.accountId,
          id_estatus: paidOffStatus.id,
          id_autor: this.userData.id, 
          id_institucion_educativa: this.institutionId,
          comentarios: this.comments
        }
        const response = await this.postObj('/administracion/crear-ingreso', incomeObj)
        if (response.mensaje.includes('operación exitosa')) {
          this.alertText = "Ingreso creado con éxito"
        } else {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear ingreso', error);
        this.errors.push('Error al intentar crear ingreso')
      }
    },
    async createDebt() {
      try {
        const debtStatus = await this.fetchResultsByEI('administracion', 'estatus-adeudos', this.institutionId)
        const penalties = await this.fetchResultsByEI('administracion', 'penalizacion', this.institutionId)
        const promptPayDiscounts = await this.fetchResultsByEI('administracion', 'descuento-pronto-pago', this.institutionId)

        const pendingStatus = debtStatus.find(pStatus => pStatus.dato.toLowerCase().includes('pendiente de pago'))
        const penalty = penalties.find(p => p.nombre.toLowerCase().includes('venta'))
        const promptPayDiscount = promptPayDiscounts
                                  .find(ppDiscount => ppDiscount.nombre_descuento.toLowerCase().includes('venta'))

        const today = new Date()

        const currentDay = today.getDate()
        const currentMonth = today.getMonth()
        const currentYear = today.getFullYear()
        const expirationDate = new Date(currentYear, currentMonth, currentDay + 10)

        const expirationDay = expirationDate.getDate()
        const expirationMonth = expirationDate.getMonth()
        const expirationYear = expirationDate.getFullYear()
        const promptPayDate = new Date(expirationYear, expirationMonth, expirationDay - 5)

        const expirationDateStr = expirationDate.toISOString().substring(0, 10)
        const promptPayDateStr = promptPayDate.toISOString().substring(0, 10)

        const debtObj = {
          id_alumno: this.customerSelected.id,
          nombre_adeudo: `Venta - Orden: ${this.orderNumber}`,
          comentarios: this.comments,
          sub_total: this.ticketSubTotalPrice,
          total_impuestos: this.ticketSubTotalTaxes,
          total_descuento: 0.00,
          total_adeudo: this.ticketTotal,
          descuento_aplicado: false,
          id_moneda: this.mxCoin.id,
          expirado: false,
          fecha_vencimiento: expirationDateStr, // 10 dias
          fecha_pronto_pago: promptPayDateStr, // 5 dias
          id_penalizacion: penalty.id, // Cual penalización establecer? De momento dejo pago tardio venta
          id_descuento_pronto_pago: promptPayDiscount.id, // Cual descuento establecer? De momento dejo Pronto pago venta
          id_institucion_educativa: this.institutionId,
          id_estatus_adeudo: pendingStatus.id, // Pendiente de pago
          id_autor: this.userData.id
        }
        const response = await this.postObj('/administracion/crear-adeudo', debtObj)
        if (response) {
          this.alertText = 'Adeudo creado con éxito'
        } else {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar crear adeudo', error);
        this.errors.push('Error al intentar crear adeudo')
      }
    },
    filterIt(arr, searchKey) {
      return arr.filter(item => item.producto.nombre_producto.toLowerCase().includes(searchKey.toLowerCase()));
    },
    calculateRest() {
      // console.log('minuendo: ', this.minuend);
      if (this.minuend === null || this.minuend === 0 || this.minuend === '') {
        this.rest = null
      } else {
        this.rest = this.minuend - this.ticketTotal
      }
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    cancel() {
      this.goTo('Ordenes')
    },
    cleanOrderSession() {
      this.cancelSessionOrder()
      this.orderStatusId = null
      this.paymentMethodId = null
      this.accountId = null
      this.minuend = 0
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.cleanOrderSession()
        this.goTo('Ordenes')
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.closeAlert()
      this.cleanOrderSession()
    },
    today() {
      const currentDate = new Date()
      return `${currentDate.toLocaleDateString('es-MX')} ${currentDate.toLocaleTimeString('es-MX')}`
    }
  }
}
</script>

<style scoped>
.no-pointer-events {
  pointer-events: none;
}
.no-border {
  border: none !important;
}
</style>